'use client'

import { useState, useCallback, useEffect, useRef } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu'
import Image from 'next/image'

const images = [
  { 
    src: "/promos/img1.jpg",
    alt: "Novembro Black - promoção de 05 até 30 de Novembro" 
  },
  { 
    src: '/promos/img2.jpg',
    alt: "BOTOX de R$900,00 por 699,99 no PIX" 
  },
  { 
    src: "/promos/img3.jpg",
    alt: "PRENCHIMENTO DE OLHEIRAS de R$800,00 por 599,99 no PIX" 
  },
  { 
    src: "/promos/img4.jpg",
    alt: "PREENCHIMENTO LABIAL de R$800,00 por 599,99 no PIX" 
  },
  { 
    src: "/promos/img5.jpg",
    alt: "PREENCHIMENTO DE BIGODE CHINÊS de R$800,00 por 599,99 no PIX" 
  }
]

const EmblaCarousel = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { 
      loop: true,
      duration: 20,
      dragFree: true
    }
  )

  const autoplayRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [canScrollPrev, setCanScrollPrev] = useState(false)
  const [canScrollNext, setCanScrollNext] = useState(false)

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setCurrentIndex(emblaApi.selectedScrollSnap())
    setCanScrollPrev(emblaApi.canScrollPrev())
    setCanScrollNext(emblaApi.canScrollNext())
  }, [emblaApi])

 
  const startAutoplay = useCallback(() => {
    if (autoplayRef.current) return
    autoplayRef.current = setInterval(() => {
      if (emblaApi) emblaApi.scrollNext()
    }, 5000) as unknown as null
  }, [emblaApi])

  const stopAutoplay = useCallback(() => {
    if (autoplayRef.current) {
      clearInterval(autoplayRef.current)
      autoplayRef.current = null
    }
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect()
    emblaApi.on('select', onSelect)
    emblaApi.on('reInit', onSelect)

    emblaApi.on('pointerDown', stopAutoplay)
    emblaApi.on('pointerUp', startAutoplay)

    startAutoplay()

    return () => {
      stopAutoplay()
      emblaApi.off('select', onSelect)
      emblaApi.off('reInit', onSelect)
      emblaApi.off('pointerDown', stopAutoplay)
      emblaApi.off('pointerUp', startAutoplay)
    }
  }, [emblaApi, onSelect, startAutoplay, stopAutoplay])

  return (
    <div className="relative w-full max-w-xl mx-auto">
      <div className="overflow-hidden rounded-lg" ref={emblaRef}>
        <div className="flex touch-pan-y">
          {images.map((image, index) => (
            <div 
              key={index} 
              className="flex-[0_0_100%] min-w-0 relative"
            >
              <Image
                src={image.src}
                alt={image.alt}
                width={450}
                height={350}
                quality={60}
                className="object-cover w-full h-full rounded-lg"
                loading={index === 0 ? "eager" : "lazy"}
              />
            </div>
          ))}
        </div>
      </div>

      <button
        type="button"
        onClick={scrollPrev}
        disabled={!canScrollPrev}
        className="absolute left-4 top-1/2 -translate-y-1/2 w-10 h-10 flex items-center justify-center bg-white/50 hover:bg-white disabled:opacity-50 disabled:hover:bg-white/80 rounded-full text-gray-800 transition-all duration-200 backdrop-blur-sm"
        aria-label="Anterior"
      >
        <LuChevronLeft className="w-6 h-6" />
      </button>

      <button
        type="button"
        onClick={scrollNext}
        disabled={!canScrollNext}
        className="absolute right-4 top-1/2 -translate-y-1/2 w-10 h-10 flex items-center justify-center bg-white/50 hover:bg-white disabled:opacity-50 disabled:hover:bg-white/80 rounded-full text-gray-800 transition-all duration-200 backdrop-blur-sm"
        aria-label="Próximo"
      >
        <LuChevronRight className="w-6 h-6" />
      </button>

      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-2 h-2 rounded-full transition-all duration-200 ${
              currentIndex === index 
                ? 'bg-white scale-125' 
                : 'bg-white/50 hover:bg-white/75'
            }`}
            aria-label={`Go to slide ${index + 1}`}
            onClick={() => emblaApi?.scrollTo(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default EmblaCarousel