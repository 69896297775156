'use client'

import Image from 'next/image'
import { Link } from 'next-view-transitions'
import { logEvent } from '@/utils/analytics'
import Curso from '../../public/assets/cursotoxina.jpg'
import Button from './Button'
import EmblaCarousel from './EmblaCarousel'

export default function Hero() {
  const handleLinkClick = (linkName: string) => {
    logEvent('link_click', {
      link_name: linkName,
      link_destination: '/cursos',
      page_section: 'hero',
      device_type: window.innerWidth < 768 ? 'mobile' : 'desktop'
    })
  }

  return (
    <section 
      className="container mx-auto px-4 py-8"
      aria-label="Seção principal com curso e carrossel"
    >
      <div className="flex flex-col md:grid md:grid-cols-2 gap-8 items-center mb-8">
        <EmblaCarousel />
        <Link 
          href="/cursos"
          className="block group relative"
          aria-label="Ver detalhes do curso de toxina botulínica"
          onClick={() => handleLinkClick('curso_toxina_botulinica')}
        >
          <div className="overflow-hidden rounded-xl">
            <Image
              src={Curso}
              alt="Curso de Imersão em Toxina Botulínica"
              className="rounded-xl shadow-lg shadow-black transition-transform duration-300 group-hover:scale-110"
              width={500}
              height={350}
              quality={60}
              placeholder="blur"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 600px"
              priority
            />
          </div>
          <div 
            className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-opacity duration-300 rounded-xl"
            aria-hidden="true"
          />
        </Link>

        <div className="w-full"></div>
      </div>

      <div className="flex justify-center">
        <Button 
          title="AGENDAR CONSULTA" 
          onClick={() => handleLinkClick('agendar_consulta')}
        />
      </div>
    </section>
  )
}