

type EventParams = Record<string, string | number | boolean | undefined>;

export const logEvent = (
  eventName: string,
  eventParams?: EventParams
) => {
  if (typeof window !== 'undefined' && (window as any).gtag) {
    const cleanParams = Object.fromEntries(
      Object.entries(eventParams || {}).filter(([_, value]) => value !== undefined)
    );


    (window as any).gtag('event', eventName, cleanParams);
    
    // Log para desenvolvimento
    if (process.env.NODE_ENV === 'development') {
      console.log('Analytics Event:', {
        eventName,
        ...cleanParams
      });
    }
  }
};

export const trackServiceClick = (serviceName: string) => {
  logEvent('service_click', {
    service_name: serviceName,
    category: 'services'
  });
};

export const trackContactClick = (method: string) => {
  logEvent('contact_click', {
    contact_method: method,
    category: 'contact'
  });
};

