'use client'

import { logEvent } from '@/utils/analytics'

interface ButtonProps {
  title: string;
  onClick?: () => void;
}

export default function Button({ title, onClick }: ButtonProps) {
  const handleClick = () => {
    logEvent('button_click', {
      button_name: title,
      destination: 'whatsapp',
      interaction_type: 'click'
    })
    window.open(
      'https://api.whatsapp.com/send?phone=5551995502553&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20agendamento%20de%20consulta.',
      '_blank'
    )
    onClick?.() 
  }
  

  return (
    <button 
      type='button'
      onClick={handleClick}
      className="
        relative
        inline-flex
        items-center
        justify-center
        px-6
        py-3
        overflow-hidden
        font-semibold
        text-white
        uppercase
        rounded-full
        group
        bg-gradient-to-r
        from-[#B4960A]
        to-[#695706]
        hover:before:translate-x-[100%]
        before:absolute
        before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2.5s_infinite]
        before:bg-gradient-to-r
        before:from-transparent
        before:via-white/20
        before:to-transparent
        before:transition-transform
        before:duration-500
        before:ease-in-out
        hover:shadow-lg
        hover:shadow-black/50
        active:scale-[0.98]
        transition-all
        duration-300
      "
    >
      {title}
    </button>
  )
}