'use client'

import Image from 'next/image'
import type { StaticImageData } from 'next/image'
import { useState, useCallback, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { logEvent } from '@/utils/analytics'

interface TreatmentCardProps {
  title: string;
  image: StaticImageData;
  description: string;
  cardIndex?: number;
}

const TreatmentCard = ({ 
  title, 
  image, 
  description,
  cardIndex 
}: TreatmentCardProps) => {
  const [isFlipped, setIsFlipped] = useState(false)

  const handleClick = useCallback(() => {
    setIsFlipped(!isFlipped)
    
    // Usando o nome do tratamento como evento principal
    const eventName = `${title.toLowerCase().replace(/\s+/g, '_')}_interaction`
    logEvent(eventName, {
      action: isFlipped ? 'unflip' : 'flip',
      card_position: cardIndex,
      device_type: window.innerWidth < 768 ? 'mobile' : 'desktop'
    })
  }, [isFlipped, title, cardIndex])

  useEffect(() => {
    // Usando o nome do tratamento como evento principal
    const eventName = `${title.toLowerCase().replace(/\s+/g, '_')}_view`
    logEvent(eventName, {
      card_position: cardIndex,
      interaction_type: 'view',
      non_interaction: true
    })
  }, [title, cardIndex])

  return (
    <div className="flex flex-col justify-center">
      <h2 className="pl-2 w-[320px] text-textcolor text-2xl uppercase py-2 font-medium">
        {title}
      </h2>
      <motion.div
        className="group h-[230px] w-[320px] relative rounded-xl cursor-pointer"
        onClick={handleClick}
        whileTap={{ scale: 0.98 }}
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.2 }}
        onHoverStart={() => {
          // Usando o nome do tratamento como evento principal
          const eventName = `${title.toLowerCase().replace(/\s+/g, '_')}_hover`
          logEvent(eventName, {
            card_position: cardIndex,
            interaction_type: 'hover'
          })
        }}
      >
        <AnimatePresence initial={false} mode="wait">
          {!isFlipped ? (
            <motion.div
              key="front"
              className="absolute inset-0"
              initial={{ rotateY: 0 }}
              animate={{ rotateY: 0 }}
              exit={{ rotateY: 90 }}
              transition={{ duration: 0.2 }}
            >
              <div className="h-full rounded-xl shadow-xl overflow-hidden">
                <Image
                  src={image}
                  height={230}
                  width={367}
                  quality={60}
                  className="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40"
                  alt={`Tratamento de ${title}`}
                  priority
                />
                <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="back"
              className="absolute inset-0"
              initial={{ rotateY: -90 }}
              animate={{ rotateY: 0 }}
              exit={{ rotateY: 90 }}
              transition={{ duration: 0.2 }}
            >
              <div className="h-full rounded-xl shadow-xl overflow-hidden bg-gradient-to-t from-textbrand to-[#695706] p-6 flex items-center justify-center">
                <p className="text-slate-200 text-sm leading-relaxed">
                  {description}
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        
        <div className="absolute bottom-2 right-2 md:hidden">
          <span className="bg-white/80 text-black text-xs px-2 py-1 rounded-full">
            Toque para {isFlipped ? 'voltar' : 'mais info'}
          </span>
        </div>
      </motion.div>
    </div>
  )
}
export default TreatmentCard