'use client'

import TreatmentCard from './treatmentCard'
import { treatmentsData } from './treatmentsData'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { logEvent } from '@/utils/analytics'

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
}

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
}

const Tratamentos = () => {
  useEffect(() => {
    // Track section view with treatment count
    logEvent('view_treatment_section', {
      event_category: 'Section View',
      event_label: 'Treatments Section',
      total_treatments: treatmentsData.length,
      non_interaction: true
    })

    // Track each treatment as a main event on section load
    treatmentsData.forEach((treatment, index) => {
      logEvent('available_treatment', {
        event_category: 'Treatment',
        event_label: treatment.title,
        treatment_type: treatment.title.toLowerCase(),
        position: index,
        non_interaction: true
      })
    })
  }, [])

  return (
    <section className="w-4/5 flex flex-col mx-auto py-10">
      <motion.h1 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        id="tratamentos" 
        className="font-display text-textbrand text-center mb-12 text-6xl md:text-6xl"
      >
        Tratamentos
      </motion.h1>
      
      <motion.div 
        variants={container}
        initial="hidden"
        animate="show"
        className="flex flex-wrap gap-8 items-center justify-center"
      >
        {treatmentsData.map((treatment, index) => (
          <motion.div key={treatment.title} variants={item}>
            <TreatmentCard
              title={treatment.title}
              image={treatment.image}
              description={treatment.description}
              cardIndex={index}
            />
          </motion.div>
        ))}
      </motion.div>
    </section>
  )
}

export default Tratamentos