import skinbooster from '../../../public/assets/skinbooster.jpg'
import peim from '../../../public/assets/peim.jpg'
import Botox from '../../../public/assets/botox1.jpg'
import Capilar from '../../../public/assets/mesoterapiacapilar.jpg'
import Microagulhamento from '../../../public/assets/microagulhamento1.jpg'
import Preenchimento from '../../../public/assets/preenchimento.jpg'
import Intradermoterapia from '../../../public/assets/intradermoterapia.jpg'
import Bioestimuladores from '../../../public/assets/bioestimuladores.jpg'
import Lipodepapada from '../../../public/assets/lipodepapada.jpg'

export const treatmentsData = [
  {
    title: 'toxina botulínica',
    image: Botox,
    description: 'É indicada para amenizar rugas e linhas de expressão da glabela, testa, nasal, lateral dos olhos, sorriso gengival e outros.'
  },
  {
    title: 'Preenchimento',
    image: Preenchimento,
    description: 'O Ácido Hialurônico aumenta a hidratação da região tratada e ajuda a dar volume, elasticidade e firmeza à pele. Resultados naturais e duradouros. Aplicação com uso de anestésicos. Exemplos são o preenchimento labial, bigode chinês e olheiras.'
  },
  {
    title: 'microagulhamento',
    image: Microagulhamento,
    description: 'Ajuda a remover cicatrizes de acne, reduzir manchas, rugas e linhas de expressão. O procedimento é feito com microagulhas que penetram na derme com permeação de ativos específicos.'
  },
  {
    title: 'lipo de papada',
    image: Lipodepapada,
    description: 'Alternativa minimamente invasiva, sem cortes e sem dor. O procedimento é feito com ácido deoxicólico, enzima produzida pelo corpo, que ajuda a destruir os reservatórios lipídicos.'
  },
  
  {
    title: 'bioestimuladores de colágeno',
    image: Bioestimuladores,
    description: 'Para quem deseja uma pele mais firme, sem rugas e sem flacidez esse é o procedimento ideal. Age favorecendo uma pele mais firme, diminuindo rugas e cicatrizes de acne.'
  },
  {
    title: 'skinbooster',
    image: skinbooster,
    description: 'É um procedimento rápido, feito com ácido hialurônico e um complexo de vitaminas, que reduz linhas finas e melhora a aparência, textura e luminosidade da pele.'
  },
  
  
  
  {
    title: 'tratamentos capilares',
    image: Capilar,
    description: 'Mesoterapia capilar é uma técnica utilizada para tratar a queda de cabelo crônica, que pode ocorrer devido ao estresse, deficiências nutricionais ou a calvice. A técnica consiste na aplicação de injeções com vitaminas ou medicamentos na região do couro cabeludo.'
  },
  
  {
    title: 'PEIM (SECAGEM DE VASINHOS)',
    image: peim,
    description: 'Procedimento Estético Injetável para microvasos. Tratamento realizado com o objetivo de eliminar os vasinhos que aparecem nas pernas e se tornam um incômodo estético para homens e mulheres.'
  },
  {
    title: 'intradermoterapia',
    image: Intradermoterapia,
    description: 'Consiste na aplicação de ativos lipolíticos (ativos capazes de "quebrar" a gordura). A aplicação é realizada com o auxílio de microagulhas na camada subcutânea da pele com o objetivo de auxiliar na eliminação de gordura.'
  },
]